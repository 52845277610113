export const booksData = [
  {
    id: 1,
    image: "https://m.media-amazon.com/images/I/81gTfV8layL._AC_UY218_.jpg",
    title: "The Dilemma: The Sunday Times Top Ten Bestseller",
    author: "B.A Paris",
  },
  {
    id: 2,
    image: "https://m.media-amazon.com/images/I/81AdMgygJaL._AC_UY218_.jpg",
    title: "The Close: The exciting new detective crime thriller ",
    author: "Maeve Kerrigan",
  },
  {
    id: 3,
    image: "https://m.media-amazon.com/images/I/81SecWqv+uL._AC_UY218_.jpg",
    title: "Kill Them Cold: A chilling British detective thriller",
    author: "JuniperPhoton",
  },
  {
    id: 4,
    image: "https://m.media-amazon.com/images/I/81gTfV8layL._AC_UY218_.jpg",
    title: "The Dilemma: The Sunday Times Top Ten Bestseller",
    author: "B.A Paris",
  },
  {
    id: 5,
    image: "https://m.media-amazon.com/images/I/81AdMgygJaL._AC_UY218_.jpg",
    title: "The Close: The exciting new detective crime thriller ",
    author: "Maeve Kerrigan",
  },
  {
    id: 6,
    image: "https://m.media-amazon.com/images/I/81SecWqv+uL._AC_UY218_.jpg",
    title: "Kill Them Cold: A chilling British detective thriller",
    author: "JuniperPhoton",
  },
  {
    id: 7,
    image: "https://m.media-amazon.com/images/I/81gTfV8layL._AC_UY218_.jpg",
    title: "The Dilemma: The Sunday Times Top Ten Bestseller",
    author: "B.A Paris",
  },
  {
    id: 8,
    image: "https://m.media-amazon.com/images/I/81AdMgygJaL._AC_UY218_.jpg",
    title: "The Close: The exciting new detective crime thriller ",
    author: "Maeve Kerrigan",
  },
  {
    id: 9,
    image: "https://m.media-amazon.com/images/I/81SecWqv+uL._AC_UY218_.jpg",
    title: "Kill Them Cold: A chilling British detective thriller",
    author: "JuniperPhoton",
  },
];
