import React from "react";
import Image from "./Image";

const Title = ({ title }) => {
  return <h4>{title}</h4>;
};
const Author = ({ author }) => <h5>Written By: {author}</h5>;

const Book = ({ image, title, author, children, id, findBook, bookNumber }) => {
  return (
    <article className="book" onClick={() => findBook(id)}>
      <p>#{bookNumber + 1}</p>
      <Image image={image} title={title} />
      <Title title={title} />
      <Author author={author.toUpperCase()} />
      {children}
    </article>
  );
};

export default Book;
