import React from "react";
import ReactDOM from "react-dom/client";
import "./app.css";
import BookList from "./Components/BookList";
import PageTitle from "./Components/PageTitle";
import { booksData } from "./books.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <PageTitle text="Book's List" />
    <BookList booksData={booksData} />
  </>
);
