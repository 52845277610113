import React, { useState } from "react";
import Book from "./Book";
import "./BookList.css";
// import { booksData } from "../books";

const BookList = ({ booksData }) => {
  const [books, setBooks] = useState(booksData);
  const findBook = (bookId) => {
    //   const book = books.find((book) => book.id === bookId);
    //   console.log(book.title);
    const book = books.filter((book) => book.id !== bookId);
    console.log(book);
    setBooks(book);
  };
  return (
    <section id="BookList">
      {books.map((book, index) => (
        <Book key={index} {...book} findBook={findBook} bookNumber={index} />
      ))}
    </section>
  );
};

export default BookList;
